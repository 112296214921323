(function(){
'use strict';

angular.module('classy.frs').component('registrationReceipt', {
  template: '\n      <checkout-section>\n        <cpt-body>\n          <ng-include src="$ctrl.tpl(\'receipt\')"></ng-include>\n        </cpt-body>\n      </checkout-section>\n    ',
  bindings: {
    type: '<'
  },
  controller: ["$animate", "$element", "scCampaignsService", "scReceiptService", "scThemesService", "scFundraisingPagesService", "scFundraisingTeamsService", "scSourceCodes", "scAnalytics", "orgContactService", function controller($animate, $element, scCampaignsService, scReceiptService, scThemesService, scFundraisingPagesService, scFundraisingTeamsService, scSourceCodes, scAnalytics, orgContactService) {
    var _this = this;

    orgContactService.getPhoneNumber().then(function (number) {
      _this.orgPhone = number;
    });

    orgContactService.getContactEmail().then(function (email) {
      _this.orgEmail = email;
    });

    /* -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      _this.receipt = scReceiptService.active;
      _this.primaryColor = scThemesService.active.current.styles.primaryColor;

      /**
       * Once registrants are populated, add fundraising pages to
       * scFundraisingPagesService (which will populate its own
       * userFundraisers property)
       */
      if (_this.type === 'reg_w_fund') {
        _this.fallbackTeamLogo = scFundraisingTeamsService.getTeamPhoto();
        _this.fallbackPageLogo = scFundraisingPagesService.getPagePhoto();

        scReceiptService.checkRegistrants().then(function (responses) {
          _.each(responses, function (response) {
            scFundraisingPagesService.add(response.fundraising_page);

            // Tell scAnalytics we've created a new fundraising page.
            scAnalytics.eventBeacon({
              category: 'frs ' + scCampaignsService.active.current.type,
              action: 'creation',
              label: 'New Fundraising Page',
              meta: scSourceCodes.getLatest(scCampaignsService.active.current.id),
              fundraiser: response.fundraising_page
            });

            // reset the reset state to update the view (needed on initial load sometimes)
            _this.receipt = scReceiptService.active;
          });
        });
      }
    };

    this.$postLink = function () {
      $animate.enabled($element, false);
    };

    this.$onDestroy = function () {
      scReceiptService.clear();
    };

    /* -------------------------------------------------------------------- *
     * Templates
     * -------------------------------------------------------------------- */

    this.tpl = function (slug) {
      return 'frs/registration/components/receipt/inc/' + slug;
    };

    this.orderDetailsText = function () {
      if (scCampaignsService.active.current.type === 'reg_w_fund') {
        return 'We send out links to fundraising pages.';
      }
      return 'Your order details will be emailed to the address provided.';
    };

    this.headerText = function () {
      if (this.type === 'ticketed') {
        return 'A copy of your receipt will be sent to your email shortly.';
      }

      return 'Your order ' + (this.receipt.donationTotal > 0 ? 'and donation have' : 'has') + ' been processed. Check your email for your confirmation receipt and instructions for how to set up your personal fundraising page. Good luck!';
    };
  }]
});
})();