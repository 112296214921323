(function(){
'use strict';

frsSearchCtrl.$inject = ["$scope", "$state", "scBlocksService", "scCampaignsService", "scPagingButtonsAPI", "scThemesService", "classyQL"];
angular.module('classy.frs').controller('frs-searchCtrl', frsSearchCtrl);

function frsSearchCtrl($scope, $state, scBlocksService, scCampaignsService, scPagingButtonsAPI, scThemesService, classyQL) {
  var PERPAGE = 10;

  /* ------------------------------------------------------------------------ *
   * State
   * ------------------------------------------------------------------------ */

  $scope.state = {
    filter: 'all',

    query: '',

    results: {
      page: [],
      team: []
    },

    fetching: {
      page: false,
      team: false
    },

    // We need a separate variable here to track whether we've made a request, so that we can
    // know for certain that we've made a request and that if fetching[type] is false, that is
    // because a request has been made and completed
    hasFetched: {
      page: false,
      team: false
    },

    primaryColor: scThemesService.active.current.styles.primaryColor,

    sortFilters: {
      page: {
        field: 'progress_bar_amount',
        direction: 'desc',
        option: 'Most raised'
      },
      team: {
        field: 'progress_bar_amount',
        direction: 'desc',
        option: 'Most raised'
      }
    },

    sortOptions: ['Most raised', 'Least raised', 'Most recent', 'First created']
  };

  $scope.theme = scThemesService.active.current;

  $scope.noResults = function () {
    return $scope.state.filter === 'all' && $scope.show.empty('team') && $scope.show.empty('page') || $scope.state.filter === 'team' && $scope.show.empty('team') || $scope.state.filter === 'page' && $scope.show.empty('page');
  };

  $scope.showGlobalSpinner = function () {
    return $scope.state.filter === 'all' && !$scope.state.hasFetched.team && !$scope.state.hasFetched.page || $scope.state.filter === 'team' && !$scope.state.hasFetched.team || $scope.state.filter === 'page' && !$scope.state.hasFetched.page;
  };

  /* ------------------------------------------------------------------------ *
   * Setters
   * ------------------------------------------------------------------------ */

  $scope.set = {
    filter: function filter(type) {
      $scope.state.filter = type;
    }
  };

  $scope.updateSort = function (type) {
    $scope.state.results[type] = [];

    switch ($scope.state.sortFilters[type].option) {
      case 'Most raised':
        $scope.state.sortFilters[type].direction = 'desc';
        $scope.state.sortFilters[type].field = 'progress_bar_amount';
        break;
      case 'Least raised':
        $scope.state.sortFilters[type].direction = 'asc';
        $scope.state.sortFilters[type].field = 'progress_bar_amount';
        break;
      case 'Most recent':
        $scope.state.sortFilters[type].direction = 'desc';
        $scope.state.sortFilters[type].field = 'created_date';
        break;
      case 'First created':
        $scope.state.sortFilters[type].direction = 'asc';
        $scope.state.sortFilters[type].field = 'created_date';
        break;
      default:
        $scope.state.sortFilters[type].direction = 'desc';
        $scope.state.sortFilters[type].field = 'progress_bar_amount';
        break;
    }

    $scope.fetch($scope.state.query, type, 1, $scope.state.sortFilters[type].direction, $scope.state.sortFilters[type].field);
    $scope.get.results(type);
  };

  /* ------------------------------------------------------------------------ *
   * Getters
   * ------------------------------------------------------------------------ */

  $scope.get = {
    initialFilter: function initialFilter() {
      var type = $state.params.type;
      if (type === 'individual') {
        return 'page';
      } else if (type === 'team') {
        return 'team';
      }
      return 'all';
    },
    initialQuery: function initialQuery() {
      return $state.params.q || '';
    },
    block: function block(type) {
      return scBlocksService.getIdByType(type);
    },
    bgData: function bgData() {
      var cfBlock = scBlocksService.getBlockByType('crowdfunding');
      return cfBlock.current.background;
    },
    bgFallback: function bgFallback() {
      return _.get(scThemesService, 'active.current.styles.background.color');
    },
    results: function results(type) {
      return $scope.state.results[type];
    },
    limit: function limit(type) {
      return $scope.state.filter === type ? PERPAGE : 3;
    }
  };

  /* ------------------------------------------------------------------------ *
   * Display flags
   * ------------------------------------------------------------------------ */

  $scope.show = {
    more: function more(type) {
      return $scope.state.filter === 'all' && $scope.get.results(type).length > 3;
    },
    empty: function empty(type) {
      return $scope.state.hasFetched[type] && !$scope.state.fetching[type] && !$scope.get.results(type).length;
    },
    results: function results(type) {
      return $scope.state.filter === 'all' || $scope.state.filter === type;
    },
    pager: function pager(type) {
      return $scope.state.filter === type;
    },
    spinner: function spinner(type) {
      return !$scope.state.hasFetched[type] || $scope.state.fetching[type];
    }
  };

  /* ------------------------------------------------------------------------ *
   * API
   * ------------------------------------------------------------------------ */

  $scope.fetch = function (query, type, page, sortDirection, sortField) {
    if (type !== 'page' && type !== 'team') {
      return;
    }

    $scope.state.results[type] = [];
    $scope.state.fetching[type] = true;
    $scope.state.hasFetched[type] = true;

    var campaignId = scCampaignsService.active.current.id;
    $scope.campaign = scCampaignsService.active.current;

    var pageFields = '\n      id\n      name\n      fundraising_team_id\n      campaign_id\n      organization_id\n      total_raised\n      goal_raw\n      fundraiser_profile_image\n      percent_to_goal\n      currency_code\n      normalized_currency_code\n      progress_bar_amount\n    ';

    var teamFields = '\n      id\n      name\n      campaign_id\n      organization_id\n      total_raised\n      goal_raw\n      percent_to_goal\n      pages_count\n      pages_count_active\n      team_profile_image\n      currency_code\n      progress_bar_amount\n    ';

    var queryFilterString = query ? ', search: { value: "' + query + '", fields: [name]}' : '';

    var querySortField = sortField || $scope.state.sortFilters[type].field;
    var querySortDirection = (sortDirection || $scope.state.sortFilters[type].direction).toUpperCase();

    var queryType = 'fundraising' + (type === 'page' ? 'Page' : 'Team') + 'SearchPublic';
    var qlQuery = '\n      query {\n        ' + queryType + '(\n          filter: { status: { eq: active }, campaign_id: { eq: ' + campaignId + ' }},\n          sort: { field: ' + querySortField + ', order: ' + querySortDirection + ' },\n          count: ' + PERPAGE + ',\n          page: ' + (page || 1) + '\n          ' + queryFilterString + '\n        ) {\n          results {\n            ' + (type === 'page' ? pageFields : teamFields) + '\n          }\n          total\n          page\n        }\n      }\n    ';

    var promise = classyQL.query({ query: qlQuery }).then(function (response) {
      if (query !== $scope.state.query) return;
      var _response$data$data$q = response.data.data[queryType],
          total = _response$data$data$q.total,
          currentPage = _response$data$data$q.page,
          results = _response$data$data$q.results;


      $scope.state.fetching[type] = false;
      $scope.state.results[type] = results.map(function (entity) {
        return classyQL.useApiv2Fields(type, entity);
      });

      var pageInformation = {
        total: total,
        current_page: currentPage,
        last_page: Math.ceil(total / PERPAGE)
      };

      $scope.pager[type].fetchComplete(pageInformation);
    }).catch(function () {
      $scope.state.fetching[type] = false;
    });

    $scope.pager[type].setCurrentPage(page || 1);
    $scope.pager[type].fetchStarted(promise);
  };

  /* ------------------------------------------------------------------------ *
   * Init
   * ------------------------------------------------------------------------ */

  $scope.state.filter = $scope.get.initialFilter();
  $scope.state.query = $scope.get.initialQuery();

  function getNewPager(type) {
    return new scPagingButtonsAPI({
      perPage: PERPAGE,
      onPageSelect: function onPageSelect(pageNum) {
        $scope.state.hasFetched.page = false;
        $scope.state.hasFetched.team = false;
        $scope.fetch($scope.state.query, type, pageNum);
      }
    });
  }

  $scope.pager = {
    page: $scope.pager && $scope.pager.page || getNewPager('page'),
    team: $scope.pager && $scope.pager.team || getNewPager('team')
  };

  $scope.$watch('state.query', function (query) {
    $scope.fetch(query, 'page', 1);
    $scope.fetch(query, 'team', 1);
  });

  $scope.$watch('state.filter', function (filter, lastFilter) {
    if (filter === lastFilter) return;
    if (filter === 'all') {
      $scope.fetch($scope.state.query, 'page', 1);
      $scope.fetch($scope.state.query, 'team', 1);
    } else {
      $scope.fetch($scope.state.query, filter, 1);
    }
  });
}
})();