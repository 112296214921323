(function(){
'use strict';

registrationCtrl.$inject = ["$scope", "$state", "$window", "registrationNavigator", "registrationRoutes", "resolvedTeam", "scBlocksService", "scCartService", "scValidityService", "scFlowModalService", "scCampaignsService", "countryBlockService"];
angular.module('classy.frs').controller('registrationCtrl', registrationCtrl);

function registrationCtrl($scope, $state, $window, registrationNavigator, registrationRoutes, resolvedTeam, scBlocksService, scCartService, scValidityService, scFlowModalService, scCampaignsService, countryBlockService) {
  var _this = this;

  var BASE_ROUTE = registrationRoutes.BASE_ROUTE,
      CAMPAIGN_ROUTE = registrationRoutes.CAMPAIGN_ROUTE,
      RECEIPT_ROUTE = registrationRoutes.RECEIPT_ROUTE,
      TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
      PAYMENT_ROUTE = registrationRoutes.PAYMENT_ROUTE;


  $scope.isMobile = window.matchMedia('(max-width: 1024px)').matches;

  /* -------------------------------------------------------------------- *
   * Set up properties to pass to cart
   * -------------------------------------------------------------------- */

  $scope.getCart = function () {
    return scCartService.active;
  };
  $scope.team = resolvedTeam;

  $scope.getSelectTicketsStep = function () {
    return $state.current.name.indexOf('new.tickets') > -1;
  };

  /* -------------------------------------------------------------------- *
   * Layout
   * -------------------------------------------------------------------- */

  $scope.isReceipt = function () {
    return $state.is(RECEIPT_ROUTE);
  };

  $scope.isPayment = function () {
    return $state.is(PAYMENT_ROUTE);
  };

  /* -------------------------------------------------------------------- *
   * Create validity model
   * -------------------------------------------------------------------- */

  scValidityService.create('registration');

  /* -------------------------------------------------------------------- *
   * Set up header
   * -------------------------------------------------------------------- */

  $scope.headerBlock = scBlocksService.getIdByType('header');

  /* -------------------------------------------------------------------- *
   * Check Country Block
   * -------------------------------------------------------------------- */

  countryBlockService.checkOrganizationRestrictions();

  /* -------------------------------------------------------------------- *
   * Transfer team properties from params to cart
   * -------------------------------------------------------------------- */

  if ($state.params.team_id || $state.params.team_name) {
    _.merge(scCartService.active.current, {
      team_id: $state.params.team_id || null,
      team_name: $state.params.team_name || null,
      team_goal: $state.params.team_goal || null,
      team_description: ($state.params.team_description || '').replace(/<[^>]*>/g, '') || null,
      team_answers: $state.params.team_answers || null
    });
  }

  $scope.exit = function () {
    $state.go(CAMPAIGN_ROUTE);
  };

  $scope.dismiss = function (refreshCart) {
    if (refreshCart) {
      scCartService.active.refresh();
    }

    scFlowModalService.close();

    if ($scope.campaign.team_membership_policy == 'required') {
      $scope.exit();
    }
  };

  $scope.enableFrMinimums = _.get(scCampaignsService, 'active.current.type') === 'reg_w_fund';

  $scope.setFrMinimumExists = function (exists) {
    $scope.frMinimumExists = exists && $state.is(TICKETS_ROUTE);
  };

  scCartService.active.on('warn', function (event) {
    $scope.timeoutWarningMessage = 'Session will time out in ' + event.timeLeft / 60000 + ' minute';

    scFlowModalService.open({
      template: '\n        <div class="rwf-checkout-modal" aria-labelledby="dialog_session_warning">\n          <div id="dialog_session_warning">\n            <h1 class="rwf-checkout-modal__title">Hey, you still there?</h1>\n            <p class="rwf-checkout-modal__subtitle">Let us know if you need more time.</p>\n            <span class="sr-only" ng-bind="timeoutWarningMessage"></span>\n          </div>\n          <div class="rwf-checkout-modal__actions">\n            <button class="rwf-checkout-modal__dismiss"\n            ng-click="dismiss(true)">Yep, still here!</button>\n            <a href="" class="rwf-checkout-modal__exit"\n            ng-click="exit()">Back to event</a>\n          </div>\n        </div>\n      ',
      context: $scope
    }, {
      elementToFocusOnClose: document.activeElement
    });
  });

  this.cartExpireListener = scCartService.onCartExpired(function () {
    registrationNavigator.go(TICKETS_ROUTE, {
      // Note: there is existing code which allows us to display the ticket select UI without having
      // to change to the ticket-select URL. This is, among other cases, used for when users are
      // trying to use browser back-buttons to navigate to the ticket select page, which we want to
      // prevent once an active cart has been established.
      // This is a bit of a hack because of how complex the registrationNavigator code is. If the
      // URL is not changed, it can leave the user in a "bad" state which will cause
      // and infinite-redirect loop between the select tickets and new attendees page.
      fromExpiredCart: true
    }, true).then(function () {
      scFlowModalService.open({
        context: $scope,
        template: '\n          <div class="rwf-checkout-modal" aria-labelledby="dialog_session_expired">\n            <div id="dialog_session_expired">\n              <h1 class="rwf-checkout-modal__title">Your session timed out.</h1>\n              <p class="rwf-checkout-modal__subtitle">\n                Sorry about that, we had to release your registrations. Please make your selection again.\n              </p>\n            </div>\n            <div class="rwf-checkout-modal__actions">\n              <button class="rwf-checkout-modal__dismiss"\n              ng-click="dismiss()">OK</button>\n              <a href="" class="rwf-checkout-modal__exit"\n              ng-click="exit()">Back to event</a>\n            </div>\n          </div>\n        '
      }, {
        transitionWhitelist: [TICKETS_ROUTE]
      });
    });
  });

  // Allow the navigator to confirm single page app navigation to views
  // outside of the checkout. This is registered here so that it will be
  // automatically deregistered on $destroy, i.e. when the user leaves the
  // checkout.
  // UNLIKE registrationNavigator.component, this handles navigation to routes
  // outside of the checkout flow. All state changes pertaining to rwf registration
  // should be handled inside of registrationNavigator.
  // RegistrationNavigator.validate takes care of pre-state change checks for reg.
  $scope.$on('$stateChangeStart', function ($event, toState, toParams) {
    if (toState.name.indexOf(BASE_ROUTE) > -1) return;
    if (registrationNavigator.validateExit()) return;

    $event.preventDefault();

    registrationNavigator.confirmExit(function (shouldExit) {
      if (shouldExit) {
        $state.go(toState, toParams);
      }
    });
  });

  // Prevent browser history modification on state change to TICKETS step
  // if the user decides to cancel navigation away from current page.
  $scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
    if ($window.piTracker) $window.piTracker(newUrl); // Pardot Tracker
    // we need to check for whether a cart is reserved here in order to not go into an infinite
    // loop when trying to re-register tickets after the user's cart expires
    // The issue is caused by bad state caused by using preventDefault on $locationChangeStart,
    // which causes problems for angular-router. However, fixing this "correctly" would require
    // rewriting most of how registrationNavigator and the exit-dialog work. The next "proper"
    // navigation after this occurs should gets the state for everything else set up properly.
    if (scCartService.active.isReserved && newUrl.indexOf('/new/select-tickets') > -1 && oldUrl.indexOf('/attendee/new') > -1) {
      event.preventDefault();
      registrationNavigator.validate(TICKETS_ROUTE);
    }
  });

  // Allow the navigator to confirm standard navigation to views outside of
  // the checkout. This needs to be manually deregistered.
  function handleUnload($e) {
    if (registrationNavigator.validateExit()) return;
    registrationNavigator.confirmUnload($e.originalEvent);
  }
  angular.element($window).on('beforeunload', handleUnload);
  $scope.$on('$destroy', function () {
    scCartService.removeCartExpiredListener(_this.cartExpireListener);
    angular.element($window).off('beforeunload', handleUnload);
  });
}
})();