(function(){
'use strict';

angular.module('classy.frs').component('registrationNav', {
  template: '\n      <div ng-if="$ctrl.showFrMinimumReserveDisclaimer() && $ctrl.getFrMinimumInfoFunc().count > 0"\n           class="fr-minimum__reserve-disclaimer">\n        By reserving, you agree to pay {{$ctrl.cart.total | currency:\'$\':2 }} and raise {{ $ctrl.getFrMinimumInfoFunc().rawTotal | currency:\'$\':2 }}.\n        If you don\'t meet the fundraising commitment by the deadline, you agree to be charged for the remainder.\n\n        The initial deadline is on {{$ctrl.getCommitmentDeadline() | date : \'longDate\'}}.\n      </div>\n\n      <nav class="registration-nav" ng-if="!$ctrl.hideNav">\n        <div class="registration-nav__buttons">\n          <button class="registration-nav__button--secondary"\n            data-ng-show="$ctrl.displayPrevBtn()"\n            data-ng-click="$ctrl.prev()"\n            data-ng-class="{ disabled: $ctrl.prevDisabled() }"\n            ng-bind="$ctrl.prevLabel()"\n            aria-label="{{$ctrl.prevAriaLabel()}}">\n          </button>\n\n          <button class="registration-nav__button"\n            data-ng-click="$ctrl.next()"\n            data-ng-style="{ \'background-color\': $ctrl.primaryColor }"\n            data-ng-class="{ disabled: $ctrl.nextDisabled() }"\n            ng-bind="$ctrl.nextLabel()"\n            aria-label="{{$ctrl.nextAriaLabel()}}"\n            aria-disabled="{{$ctrl.nextDisabled() ? true:false}}"\n            tabindex="{{$ctrl.nextDisabled() ? -1 : 0}}">\n          </button>\n        </div>\n\n        <div class="registration-nav__total">\n          <span class="registration-nav__total-label">{{ $ctrl.getFrMinimumInfoFunc().count > 0 ? \'Due Today\' : \'Total\' }}</span>\n          <span class="registration-nav__total-amount">\n            {{$ctrl.cart.total | currency:\'$\':2 }}\n          </span>\n        </div>\n      </nav>\n    ',
  bindings: {
    cart: '<',
    enableFrMinimums: '<'
  },
  controller: ["$rootScope", "$state", "registrationNavigator", "registrationRoutes", "scBanner", "scCartService", "scThemesService", "scValidityService", "scCampaignsService", function RegistrationNavCtrl($rootScope, $state, registrationNavigator, registrationRoutes, scBanner, scCartService, scThemesService, scValidityService, scCampaignsService) {
    var _this = this;

    var TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
        ATTENDEE_ROUTE = registrationRoutes.ATTENDEE_ROUTE,
        DONATION_ROUTE = registrationRoutes.DONATION_ROUTE,
        PAYMENT_ROUTE = registrationRoutes.PAYMENT_ROUTE,
        RECEIPT_ROUTE = registrationRoutes.RECEIPT_ROUTE;


    var validityModel = scValidityService.get('registration');
    var campaignStatus = scCampaignsService.active.current.status;
    var HAS_FUNDRAISING = scCampaignsService.active.HAS_FUNDRAISING;
    var hasDonation = _.get(scThemesService.active.getEventStyles(), 'ticketedEvent.additionalDonation', true);

    /* -------------------------------------------------------------------- *
     * Style
     * -------------------------------------------------------------------- */

    this.primaryColor = scThemesService.active.current.styles.primaryColor;

    /* -------------------------------------------------------------------- *
     * Navigation
     * -------------------------------------------------------------------- */
    this.displayPrevBtn = function () {
      return !$state.is(TICKETS_ROUTE);
    };

    this.prevDisabled = function () {
      return $state.is(TICKETS_ROUTE) || _this.loading();
    };

    this.nextDisabled = function () {
      if (campaignStatus != 'active') {
        return true;
      }
      switch ($state.current.name) {
        case TICKETS_ROUTE:
          return !_this.cart.stagedItems.length || _this.loading();
        case ATTENDEE_ROUTE:
          return !_.get(validityModel.get('attendeeForm'), 'valid') || _this.loading();
        case DONATION_ROUTE:
          return _this.loading();
        case PAYMENT_ROUTE:
          return !_.get(validityModel.get('paymentForm'), 'valid') || _this.loading();
        case RECEIPT_ROUTE:
          return true;
        default:
          return false;
      }
    };

    this.prevLabel = function () {
      // RWF / general stuff
      var cartItemId = _.parseInt($state.params.cartItemId);
      var firstReg = _.get(this, 'cart.current.items[0].registrants.length') == 0 || _.get($state, 'params.attendeeId') == _.get(this, 'cart.current.items[0].registrants[0].id');

      // Only display 'Start Over' if it's the first new attendee in the cart.
      // This handles multi attendee tickets.
      if (firstReg && cartItemId === _.get(this.cart, ['current', 'items', 0, 'id'])) {
        return 'Start Over';
      } else if ($state.is(PAYMENT_ROUTE) && this.cart.isErrored) {
        return 'Start Over';
      } else if (!HAS_FUNDRAISING) {
        // TICKETED stuff
        // items in the cart with questions
        var cartQuestionItems = this.cart.itemsWithQuestions(_.get(this.cart, 'current.items', []));

        // is this the first step after attendee info?
        var firstStepPostAttendee = $state.is(DONATION_ROUTE) || $state.is(PAYMENT_ROUTE) && !hasDonation;

        // index (within this.cart.current.items) of the first item with questions
        var firstQuestionIdx = this.cart.firstItemWithQuestionsIdx(_.get(this.cart, 'current.items', []));

        // the first question item itself
        var firstQuestionItem = _.get(this.cart, ['current', 'items', firstQuestionIdx], {});

        // the first question item either has no completed registrants, or we are routed on
        // the very first registrant of the first question item
        var firstQuestionReg = _.get(firstQuestionItem, 'registrants.length') == 0 || _.get($state, 'params.attendeeId') == _.get(firstQuestionItem, 'registrants[0].id');

        // if there are no question items and this is either the donation page or the
        // payment page when additional donation is turned off, it should say start over
        if (!cartQuestionItems.length && firstStepPostAttendee) {
          return 'Start Over';
        }
        // it should also say start over if it is the first registrant of the first questionItem
        if (cartItemId === firstQuestionItem.id && firstQuestionReg) {
          return 'Start Over';
        }
      }

      return 'Back';
    };

    this.nextLabel = function () {
      switch ($state.current.name) {
        case TICKETS_ROUTE:
          return _.get(scThemesService, 'active.current.styles.registerBtnText', 'Reserve');
        case DONATION_ROUTE:
          return this.cart.current.donation_amount ? 'Checkout' : 'Skip';
        case PAYMENT_ROUTE:
          return this.cart.total === 0 ? 'Reserve' : 'Purchase';
        default:
          return 'Next';
      }
    };

    this.hideNav = $state.current.name === RECEIPT_ROUTE;

    this.showFrMinimumReserveDisclaimer = function () {
      return _this.enableFrMinimums && $state.current.name === TICKETS_ROUTE;
    };

    this.loading = function () {
      return registrationNavigator.stateChangeActive;
    };

    this.prev = function () {
      // Probably not the best place for this ... but it works for now
      if (!this.prevDisabled() && $state.is(PAYMENT_ROUTE) && this.cart.isErrored) {
        if (_.get(this.cart, 'metadata.partialPaymentSuccess')) {
          $rootScope.$broadcast('registrationAlert', true, {
            title: 'This will release your registrations.',
            description: 'Your payment has been partially processed. Are you sure you want to start over?',
            dismissText: 'Yes, start over',
            dismiss: function dismiss() {
              scCartService.create().then(function () {
                scBanner.close();
                $rootScope.$broadcast('registrationAlert', false);
                registrationNavigator.go(TICKETS_ROUTE);
              });
            },
            exitText: 'Stay on this page',
            exit: function exit() {
              $rootScope.$broadcast('registrationAlert', false);
            }
          });
        } else {
          registrationNavigator.confirmExit(function () {
            return $state.go(TICKETS_ROUTE);
          });
        }

        return false;
      } else if (!this.prevDisabled()) {
        registrationNavigator.prev();
      }

      return undefined;
    };

    this.next = function () {
      if (!this.nextDisabled()) {
        if (!this.cart.current.donation_amount) {
          this.cart.current.donation_amount = null;
          this.cart.current.donation_answers = null;
          this.cart.current.donation_frequency = null;
          this.cart.current.donation_fee_on_top = null;
        }

        registrationNavigator.next();
      } else if (campaignStatus != 'active') {
        scBanner('Whoops, this is just a preview. Please publish your campaign first.');
      }
    };

    this.calculateFrMinimumTotal = function (stagedItems) {
      var commitmentItems = stagedItems.filter(function (si) {
        return !!si.commitment;
      });
      if (!commitmentItems.length) {
        this.frMinimumTotal = 0;
      } else {
        var commitmentItemsWithFrMin = commitmentItems.map(function (ci) {
          var registrantCount = ci.registrants ? ci.registrants.length : 0;
          ci.frMin = registrantCount * ci.commitment.value;
          return ci;
        });

        this.frMinimumTotal = commitmentItemsWithFrMin.map(function (ci) {
          return ci.frMin;
        }).reduce(function (a, b) {
          return a + b;
        });
        this.firstFrMinimumDue = commitmentItemsWithFrMin.sort(function (ci1, ci2) {
          return new Date(ci1.commitment.deadline) - new Date(ci2.commitment.deadline);
        })[0];
      }
      return this.frMinimumTotal;
    };

    this.getFrMinimumInfoFunc = function () {
      return scCartService.getFrMinimumInfo();
    };

    this.getCommitmentDeadline = function () {
      return new Date(_this.getFrMinimumInfoFunc().firstDue.commitment.deadline);
    };

    this.nextAriaLabel = function () {
      switch ($state.current.name) {
        case TICKETS_ROUTE:
          return 'Click to proceed with ticket completion, attendee information on next page.';
        case DONATION_ROUTE:
          return this.cart.current.donation_amount ? 'Click to proceed to Checkout page to complete registration.' : 'Click to bypass Add a Donation page and proceed to Checkout page to complete registration.';
        case PAYMENT_ROUTE:
          return 'Click to complete registration process.';
        default:
          return hasDonation ? 'Click to proceed to Add a Donation page to make optional add-on donation.' : 'Click to proceed to Checkout page to complete registration.';
      }
    };

    this.prevAriaLabel = function () {
      switch ($state.current.name) {
        case DONATION_ROUTE:
          return 'Click to return to Complete Tickets page to edit attendee information.';
        case PAYMENT_ROUTE:
          return hasDonation ? 'Click to return to Add a Donation page to make optional add-on donation.' : 'Click to return to Complete Tickets page to edit attendee information.';
        default:
          return 'Click to reset ticket selection and return to Select Tickets page.';
      }
    };
  }]
});
})();