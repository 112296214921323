(function(){
'use strict';

frsRegistrationRoutesConfig.$inject = ["$stateProvider"];
angular.module('classy.frs').config(frsRegistrationRoutesConfig);

function frsRegistrationRoutesConfig($stateProvider) {
  $stateProvider.state('frs.landing.name.campaign.registration', {
    abstract: true,
    url: '/register',
    params: {
      team_id: null,
      team_name: null,
      team_goal: null,
      team_description: null,
      team_answers: null
    },
    data: {
      eventOnly: true
    },
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/registration/template',
        controller: 'registrationCtrl'
      }
    },
    resolve: {
      resolvedCart: ['scCartService', '$stateParams', function (scCartService, $stateParams) {
        return scCartService.sync($stateParams.campaignId);
      }],
      resolvedTeam: ['$stateParams', 'resolvedCart', 'scFundraisingTeamsService', function ($stateParams, resolvedCart, scFundraisingTeamsService) {
        var teamId = $stateParams.team_id || resolvedCart.current.team_id;
        if (teamId) {
          return scFundraisingTeamsService.fetch(teamId);
        }
        return null;
      }]
    }
  }).state('frs.landing.name.campaign.registration.new', {
    abstract: true,
    template: '<ui-view></ui-view>',
    url: '/new'
  }).state('frs.landing.name.campaign.registration.new.tickets', {
    url: '/select-tickets',
    template: '<registration-tickets></registration-tickets>',
    metaTags: {
      title: ['scCampaignsService', 'scThemesService', '$filter', function (scCampaignsService, scThemesService, $filter) {
        var term = $filter('capitalize')(scThemesService.active.getTicketTerm('plural'));
        return _.get(scCampaignsService, 'active.saved.name', false) + ' ' + term;
      }],
      description: ['scMetaTags', function (scMetaTags) {
        return scMetaTags.getEntityDescription('page');
      }],
      properties: {
        'og:image': ['scBrandingService', function (scBrandingService) {
          var fundraiserImage = scBrandingService.getShareImage('campaign');
          return fundraiserImage;
        }],

        'twitter:image': ['scBrandingService', function (scBrandingService) {
          var fundraiserImage = scBrandingService.getShareImage('fundraiser');
          return fundraiserImage;
        }],

        'twitter:title': ['scCampaignsService', 'scThemesService', '$filter', function (scCampaignsService, scThemesService, $filter) {
          var term = $filter('capitalize')(scThemesService.active.getTicketTerm('plural'));
          return _.get(scCampaignsService, 'active.saved.name', false) + ' ' + term;
        }],

        'og:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('page');
        }],

        'twitter:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('page');
        }]
      }
    },
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.new.tickets', $stateParams);
      }]
    }
  }).state('frs.landing.name.campaign.registration.existing', {
    abstract: true,
    template: '<ui-view autoscroll="true"></ui-view>',
    url: '/{cartId:[0-9]+}'
  }).state('frs.landing.name.campaign.registration.existing.attendee', {
    abstract: true,
    template: '<ui-view autoscroll="true"></ui-view>',
    url: '/ticket/:cartItemId/attendee'
  }).state('frs.landing.name.campaign.registration.existing.attendee.new', {
    url: '/new',
    template: '<registration-attendee></registration-attendee>',
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.existing.attendee.new', $stateParams);
      }]
    },
    metaTags: {
      title: 'Edit Attendee'
    }
  }).state('frs.landing.name.campaign.registration.existing.attendee.existing', {
    url: '/:attendeeId',
    template: '<registration-attendee></registration-attendee>',
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.existing.attendee.existing', $stateParams);
      }]
    }
  }).state('frs.landing.name.campaign.registration.existing.captain', {
    url: '/select-captain',
    template: '<registration-captain></registration-captain>',
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.existing.captain', $stateParams);
      }]
    },
    metaTags: {
      title: 'Select Team Captain'
    }
  }).state('frs.landing.name.campaign.registration.existing.donation', {
    url: '/add-donation',
    template: '<registration-donation></registration-donation>',
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.existing.donation', $stateParams);
      }]
    },
    metaTags: {
      title: 'Additional Donation'
    }
  }).state('frs.landing.name.campaign.registration.existing.payment', {
    url: '/checkout',
    template: '<registration-payment></registration-payment>',
    resolve: {
      canEnter: ['registrationNavigator', '$stateParams', function (registrationNavigator, $stateParams) {
        return registrationNavigator.validate('frs.landing.name.campaign.registration.existing.payment', $stateParams);
      }]
    },
    metaTags: {
      title: 'Checkout'
    }
  }).state('frs.landing.name.campaign.registration.existing.receipt', {
    url: '/thank-you?verify&total',
    // commenting this out in case we want to migrate both RWF and 'TICKETED' events to
    // the new 'eventReceipt' component (FRS-7081). In that event we can just delete the
    // components/receipt/template and just use the event-receipt component as the template.
    // template: '<registration-receipt></registration-receipt>',
    templateUrl: 'frs/registration/components/receipt/template',
    // reloadOnSearch set false allows us to remove the verify string after the
    // receipt totals and registrants have been loaded, without refreshing the ui.
    // If anyone shares the thank-you page then subsequent visits will not trigger
    // any calls meant for the original purchaser
    reloadOnSearch: false,
    resolve: {
      resolvedReceipt: ['scReceiptService', '$stateParams', function (scReceiptService, $stateParams) {
        return scReceiptService.load($stateParams.cartId, $stateParams.verify, $stateParams.total);
      }],
      resolvedCart: ['scCartService', function (scCartService) {
        return scCartService.create();
      }]
    },
    metaTags: {
      title: 'Thank you!'
    }
  });
}
})();