(function(){
'use strict';

angular.module('classy.frs').component('registrationAttendee', {
  template: '\n      <checkout-section>\n\n        <cpt-headline>Complete {{::$ctrl.ticketTerm | capitalize}}</cpt-headline>\n\n        <cpt-subheadline>\n          {{ $ctrl.cartItemIndex + 1 }} of {{ $ctrl.totalItems() }}\n        </cpt-subheadline>\n\n        <cpt-body aria-labelledby="attendeeForm__ticket-name">\n          <panel>\n            <h2 class="attendee-form__name" id="attendeeForm__ticket-name">{{:: $ctrl.ticketType.name}}</h2>\n            <ul class="attendee-form__nav">\n              <li class="attendee-form__nav-dot {{$index + 1}}"\n              ng-repeat="attendee in $ctrl.attendeesNavigator track by $index"\n              ng-class="{\'active\': $index + 1 == $ctrl.attendeeFormNum}"\n              ng-click="$ctrl.changeAttendeeForm($index)"></li>\n            </ul>\n\n            <form name="$ctrl.attendeeForm"\n            sc-validity-reporter="$ctrl.formName"\n            sc-validity-model="$ctrl.validityModel"\n            ng-submit="$ctrl.submit()"\n            novalidate>\n              <ng-include src="$ctrl.tpl(\'info\')"></ng-include>\n\n              <ng-include src="$ctrl.tpl(\'emergency\')"\n              ng-if="$ctrl.showEmergencyQuestions"></ng-include>\n\n              <ng-include src="$ctrl.tpl(\'shirt\')"\n              ng-if="$ctrl.showQuestion(\'shirt_size\')"></ng-include>\n\n              <ng-include src="$ctrl.tpl(\'waiver\')"\n              ng-if="$ctrl.showQuestion(\'event_waiver\')"></ng-include>\n\n              <ng-include src="$ctrl.tpl(\'custom-questions\')"\n              ng-if="$ctrl.customQuestions.length"></ng-include>\n\n              <!-- Required for implicit submit -->\n              <input type="submit" style="display:none;" />\n\n            </form>\n          </panel>\n        </cpt-body>\n\n      </checkout-section>\n    ',
  controller: ["$state", "$filter", "scBanner", "CQAnswerModel", "registrationNavigator", "registrationRoutes", "scCampaignsService", "scFlowModalService", "scCartService", "scValidityService", "scThemesService", "accessibilityService", function controller($state, $filter, scBanner, CQAnswerModel, registrationNavigator, registrationRoutes, scCampaignsService, scFlowModalService, scCartService, scValidityService, scThemesService, accessibilityService) {
    var _this2 = this;

    var cart = scCartService.active;

    var CAMPAIGN_ROUTE = registrationRoutes.CAMPAIGN_ROUTE,
        TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
        ATTENDEE_ROUTE = registrationRoutes.ATTENDEE_ROUTE,
        NEW_ATTENDEE_ROUTE = registrationRoutes.NEW_ATTENDEE_ROUTE;


    var registrantsSum = 0;

    /* -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */
    this.$onInit = function () {
      this.deregisterBeforeChange = this.onBeforeChange();
      this.deregisterChange = this.onChange();
      this.attendeeFormNum = _.parseInt(this.registrantIndex) + 1 || 1;
      this.HAS_FUNDRAISING = scCampaignsService.active.HAS_FUNDRAISING;

      var cartItems = angular.copy(cart.current.items);
      var traversedItems = cartItems.splice(0, this.cartItemIndex);
      _.forEach(traversedItems, function (item) {
        registrantsSum += item.entries;
      });
      this.attendeeNum = this.attendeeFormNum + registrantsSum;
      this.ticketTerm = scThemesService.active.getTicketTerm('plural');
      accessibilityService.initiatePageFocus('.checkout-layout__main');
    };

    this.$onDestroy = function () {
      this.deregisterBeforeChange();
      this.deregisterChange();
    };

    this.$doCheck = function () {
      if (_.parseInt(initialQuantity) !== _.parseInt(cart.quantity)) {
        initialQuantity = this.totalItems();
        this.cartItemIndex = _.findIndex(cart.current.items, {
          id: _.parseInt($state.params.cartItemId)
        });
      }
      if (!this.HAS_FUNDRAISING) {
        if (this.showQuestion('first_name') && this.registrant.first_name != this.firstNameModel()) {
          this.registrant.first_name = this.firstNameModel();
        }
        if (this.showQuestion('last_name') && this.registrant.last_name != this.lastNameModel()) {
          this.registrant.last_name = this.lastNameModel();
        }
        if (this.showQuestion('email_address') && this.registrant.email_address != this.emailAddressModel()) {
          this.registrant.email_address = this.emailAddressModel();
        }
      }
    };
    /* -------------------------------------------------------------------- *
     * Nav interceptors
     * -------------------------------------------------------------------- */

    this.onBeforeChange = function () {
      var _this = this;

      return registrationNavigator.on('beforeChange',
      // eslint-disable-next-line no-unused-vars
      function (fromRoute, fromParams, toRoute, toParams) {
        if ((fromRoute === ATTENDEE_ROUTE || fromRoute === NEW_ATTENDEE_ROUTE) && toRoute !== TICKETS_ROUTE) {
          if (_this.validityModel.reporters[_this.formName].invalid) {
            _this.validityModel.reporters[_this.formName].setSubmitState(true);
            scBanner('Please correct the invalid fields.', 'error');
            return false;
          }
          return cart.setRegistrant(_this.cartItem.id, _this.registrantIndex, _this.registrant);
        }

        return undefined;
      });
    };

    this.onChange = function () {
      // eslint-disable-next-line no-unused-vars
      return registrationNavigator.on('change', function (fromRoute, fromParams, toRoute, toParams) {
        if (fromRoute === ATTENDEE_ROUTE && toRoute === TICKETS_ROUTE && scCartService.active.isReserved) {
          registrationNavigator.confirmExit(function (reroute) {
            if (reroute) {
              scCartService.create().then(function () {
                scFlowModalService.close();
                if (scCampaignsService.active.current.team_membership_policy == 'required') {
                  $state.go(CAMPAIGN_ROUTE);
                } else {
                  registrationNavigator.go(TICKETS_ROUTE);
                }
              });
            }
          });
          return false;
        }

        return undefined;
      });
    };

    /* -------------------------------------------------------------------- *
     * Templates
     * -------------------------------------------------------------------- */

    this.tpl = function (slug) {
      return 'frs/registration/components/attendee/inc/' + slug;
    };

    /* -------------------------------------------------------------------- *
     * Form and validity
     * -------------------------------------------------------------------- */

    this.validityModel = scValidityService.get('registration').clear();

    this.formName = 'attendeeForm';

    this.submit = function () {
      var reporter = this.validityModel.reporters[this.formName];
      return reporter.valid && registrationNavigator.next();
    };

    this.changeAttendeeForm = function (index) {
      var reporter = this.validityModel.reporters[this.formName];
      var toParams = {
        cartItemId: this.cartItem.id,
        cartId: $state.params.cartId
      };

      var toRoute = ATTENDEE_ROUTE;
      var prevAttendee = void 0,
          nextAttendee = void 0;

      // Exit if invalid form or already on selected form
      if (!reporter.valid || index == this.registrantIndex) {
        // set submitted so they see the errorz
        return;
      }

      // If we're currently on a NEW attendee form, the next attendee form
      // should be new also.
      var currItem = _.get(cart.current, ['items', this.cartItemIndex]);
      var currRegistrants = _.get(currItem, 'registrants.length');

      if (index == this.registrantIndex + 1) {
        // If the next attendee in the array has already been created, get id.
        nextAttendee = _.get(currItem, ['registrants', index], false);

        if (nextAttendee) {
          nextAttendee = _.get(currItem, ['registrants', index]);
          toParams.attendeeId = nextAttendee.id;
        } else {
          toRoute = NEW_ATTENDEE_ROUTE;
          toParams.attendeeIndex = index;
        }
      } else if (index == this.registrantIndex - 1) {
        prevAttendee = _.get(currItem, ['registrants', index]);
        toParams.attendeeId = prevAttendee.id;
      }
      if (index < this.registrantIndex) {
        // If we're going backwards, attendee must already exist.
        // otherwise, check if attendee at selected index exists.
        prevAttendee = _.get(currItem, ['registrants', index]);
        toParams.attendeeId = prevAttendee.id;
      } else if (index > this.registrantIndex) {
        if (index == currRegistrants - 1) {
          nextAttendee = _.get(currItem, ['registrants', index]);
          toParams.attendeeId = nextAttendee.id;
        } else {
          toRoute = NEW_ATTENDEE_ROUTE;
          toParams.attendeeIndex = index;
        }
      }

      registrationNavigator.go(toRoute, toParams);
    };

    /* -------------------------------------------------------------------- *
     * Gather relevant models
     *
     * General note: For Phase 1, every cart item has a maximum of one
     * registrant.
     * -------------------------------------------------------------------- */

    // The index of the cart item this view corresponds to.
    this.cartItemIndex = _.findIndex(cart.current.items, {
      id: _.parseInt($state.params.cartItemId)
    });

    // The cartItem this view corresponds to.
    this.cartItem = cart.stagedItems[this.cartItemIndex];

    // Navigation dots for each attendee form
    var stagedItem = _.get(cart, ['stagedItems', this.cartItemIndex]);
    this.attendeesNavigator = _.get(stagedItem, 'registrants', {});

    // Total items in the cart.
    this.totalItems = function () {
      return cart.quantity;
    };
    var initialQuantity = cart.quantity;

    // The ticketType this cartItem represents.
    this.ticketType = _.find(scCampaignsService.active.current.ticketTypes, {
      id: this.cartItem.product_id
    });

    /* -------------------------------------------------------------------- *
     * Get registrant
     * -------------------------------------------------------------------- */
    var currItem = _.get(cart.current, ['items', this.cartItemIndex]);
    var currRegistrants = _.get(currItem, 'registrants.length');

    // When creating a new attendee, we're always going to be filling
    // the last index of the registrants index.
    if ($state.current.name == NEW_ATTENDEE_ROUTE) {
      this.registrantIndex = currRegistrants;
    } else {
      this.registrantIndex = _.findIndex(currItem.registrants, function (reg) {
        return reg.id == $state.params.attendeeId;
      });
    }

    this.attendeeFormNum = _.parseInt(this.registrantIndex) + 1;

    // The registrant this view belongs to.
    this.registrant = _.get(this.cartItem, ['registrants', this.registrantIndex]);

    // If a new registrant object was returned, sync it to stagedItems
    if (!this.cartItem.id) {
      this.cartItem.registrants[this.registrantIndex] = this.registrant;
    }

    /* -------------------------------------------------------------------- *
     * Optional/custom questions
     * -------------------------------------------------------------------- */

    // The questions for this campaign's registration flow.
    this.questions = _(scCampaignsService.active.current.questions).filter({
      location: 'campaign_registration',
      is_deleted: false
    }).filter(function (question) {
      return _.includes([null, _this2.cartItem.product_id], question.product_id);
    }).value();

    // The subset of questions which are custom questions.
    var unorderedCustomQs = _.filter(this.questions, function (q) {
      return !q.tag;
    });

    this.customQuestions = $filter('orderBy')(unorderedCustomQs, 'weight', false);

    // Models
    this.firstNameModel = new CQAnswerModel('first_name', this.questions, this.registrant.answers);
    this.prefixModel = new CQAnswerModel('prefix', this.questions, this.registrant.answers);
    this.suffixModel = new CQAnswerModel('suffix', this.questions, this.registrant.answers);
    this.middleNameModel = new CQAnswerModel('middle_name', this.questions, this.registrant.answers);
    this.lastNameModel = new CQAnswerModel('last_name', this.questions, this.registrant.answers);
    this.emailAddressModel = new CQAnswerModel('email_address', this.questions, this.registrant.answers);

    this.birthDateModel = new CQAnswerModel('birthdate', this.questions, this.registrant.answers);
    this.emergencyFirstNameModel = new CQAnswerModel('emergency_first_name', this.questions, this.registrant.answers);
    this.emergencyLastNameModel = new CQAnswerModel('emergency_last_name', this.questions, this.registrant.answers);
    this.emergencyPhoneModel = new CQAnswerModel('emergency_phone', this.questions, this.registrant.answers);
    this.cellPhoneModel = new CQAnswerModel('cellphone', this.questions, this.registrant.answers);
    this.homePhoneModel = new CQAnswerModel('homephone', this.questions, this.registrant.answers);
    this.companyNameModel = new CQAnswerModel('company_name', this.questions, this.registrant.answers);
    this.genderModel = new CQAnswerModel('gender', this.questions, this.registrant.answers);
    this.pronounModel = new CQAnswerModel('pronoun', this.questions, this.registrant.answers);
    this.websiteModel = new CQAnswerModel('website', this.questions, this.registrant.answers);
    this.blogModel = new CQAnswerModel('blog', this.questions, this.registrant.answers);
    this.addressModel = new CQAnswerModel('address', this.questions, this.registrant.answers);
    this.address2Model = new CQAnswerModel('address2', this.questions, this.registrant.answers);
    this.cityModel = new CQAnswerModel('city', this.questions, this.registrant.answers);
    this.stateModel = new CQAnswerModel('state', this.questions, this.registrant.answers);
    this.postalCodeModel = new CQAnswerModel('postal_code', this.questions, this.registrant.answers);

    // Required and display flags
    this.isRequired = function (tag) {
      return !!_.find(_this2.questions, { tag: tag }).is_required;
    };
    this.showQuestion = function (tag) {
      return !!_.find(_this2.questions, { tag: tag });
    };

    this.questions.forEach(function (question) {
      if (['prefix', 'gender', 'pronoun'].includes(question.tag) && (question.enum_options || []).length) {
        _this2[question.tag + 'Options'] = question.enum_options.map(function (option) {
          return option.label;
        });
      }
    });

    if (this.showQuestion('country')) {
      this.countryModel = new CQAnswerModel('country', this.questions, this.registrant.answers);
    } else {
      this.countryModel = null;
    }

    // Not sure these are going to be active any time soon
    // this.eventWaiverModel = new CQAnswerModel('event_waiver',
    // this.questions, this.registrant.answers);
    // this.runWalkModel = new CQAnswerModel('run_walk', this.questions, this.registrant.answers);
    // this.shirtSizeModel = new CQAnswerModel('shirt_size',
    // this.questions, this.registrant.answers);

    // Display flag for emergency component
    this.showEmergencyQuestions = this.showQuestion('emergency_first_name') || this.showQuestion('emergency_last_name') || this.showQuestion('emergency_phone');
  }]
});
})();