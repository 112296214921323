(function(){
'use strict';

angular.module('classy.frs').component('registrantFundraiser', {
  template: '\n      <article class="registrant">\n\n        <div class="registrant__avatar">\n          <img class="registrant__avatar-image"\n          ng-src="{{:: $ctrl.logo }}"\n          alt="{{:: $ctrl.fundraiserName}} avatar image" />\n        </div>\n\n        <div class="registrant__fundraiser">\n\n          <h2 class="registrant__fundraiser-name">\n            {{:: $ctrl.fundraiserName }}\n          </h2>\n\n          <p class="registrant__fundraiser-details">\n            {{:: $ctrl.detailText }}\n          </p>\n\n        </div>\n\n        <div class="registrant__action">\n          <a class="registrant__action-button"\n          ui-sref="frs.fundraiser.index({ fundraisingPageId: $ctrl.fundraiser.fundraising_page.id, is_new: true })"\n          ng-style="{ \'background-color\': $ctrl.primaryColor }">\n            View\n          </a>\n        </div>\n\n      </article>\n    ',
  bindings: {
    fundraiser: '<',
    fallbackLogoUrl: '<',
    primaryColor: '<'
  },
  controller: function controller() {
    this.$onInit = function () {
      this.logo = this.fundraiser.fundraising_page.logo_url || this.fallbackLogoUrl;
      this.fundraiserName = this.fundraiser.first_name + ' ' + this.fundraiser.last_name;
      this.detailText = this.fundraiser.fundraising_page.fundraising_team_id ? 'joined the team!' : 'registered!';
    };
  }
});
})();