(function(){
'use strict';

frsCreateFundraisingTeamCtrl.$inject = ["$scope", "createFundraisingTeamFlow", "scOrganizationsService", "scCampaignsService", "scThemesService"];
angular.module('classy.frs').controller('frs-createFundraisingTeamCtrl', frsCreateFundraisingTeamCtrl);

function frsCreateFundraisingTeamCtrl($scope, createFundraisingTeamFlow, scOrganizationsService, scCampaignsService, scThemesService) {
  /* ---------------------------------------------------------------------- *
   * Configure block rendering
   * ---------------------------------------------------------------------- */

  $scope.public = true;
  $scope.editable = false;
  $scope.demo = false;
  $scope.organization = scOrganizationsService.active.current;
  $scope.campaign = scCampaignsService.active.current;
  $scope.theme = scThemesService.active.current;
  $scope.page = $scope.theme.pages.team;

  $scope.beginFlow = function () {
    $scope.team = createFundraisingTeamFlow();
  };

  /* ---------------------------------------------------------------------- *
   * Launch flow
   *
   * Returns the empty team model that will be used in both the flow and
   * for block rendering.
   * ---------------------------------------------------------------------- */
  $scope.beginFlow();
}
})();