(function(){
'use strict';

angular.module('classy.frs').component('donationCreditee', {
  template: '\n    <div class="additional-donation__credit"\n    ng-if="$ctrl.allRegistrants.length > 1">\n      <label class="additional-donation__credit-label">\n        Who should we credit for this donation?\n      </label>\n      <dropdown-control\n      data-options="$ctrl.allRegistrants"\n      ng-model="$ctrl.crediteeId"\n      name="donation_creditee">\n      </dropdown-control>\n    </div>\n  ',
  bindings: {
    cartItems: '<',
    crediteeId: '='
  },
  controller: function controller() {
    var _this = this;

    this.$onInit = function () {
      _this.allRegistrants = [];
      _this.setupDropdown();
    };

    // Update creditee dropdown if the user removes registrants
    // from the cart.
    this.$onChanges = function () {
      _this.setupDropdown();
    };

    this.setupDropdown = function () {
      _this.allRegistrants = [];
      _.forEach(_this.cartItems, function (cartItem) {
        _.forEach(cartItem.registrants, function (reg) {
          _this.allRegistrants.push({
            label: reg.first_name + ' ' + reg.last_name,
            value: reg.id
          });
        });
      });

      if (_this.allRegistrants.length > 1) {
        _this.allRegistrants.splice(0, 0, { label: 'Please select...', value: null });
      } else if (_this.allRegistrants.length == 1) {
        _this.crediteeId = _this.allRegistrants[0].value;
      }
    };
  }
});
})();