(function(){
'use strict';

angular.module('classy.frs').value('registrationResponseMock', {
  donation_total: '27.15',
  registrations_total: '110.00',
  registrants: [{
    first_name: 'Buster',
    last_name: 'Brown',
    member_id: 2821837,
    fundraising_page: {
      alias: null,
      campaign_id: 164598,
      commitment: null,
      cover_photo_id: null,
      created_at: '2017-02-22T15:56:57+0000',
      designation_id: null,
      ended_at: null,
      fundraising_team_id: 102761,
      goal: null,
      id: 525084,
      intro_text: 'Help us raise money for CP+WePay',
      logo_id: null,
      logo_url: null,
      member_email_text: null,
      member_id: 2821837,
      organization_id: 31836,
      started_at: null,
      status: 'pending_questions',
      team_role: null,
      thank_you_text: null,
      thankyou_email_text: null,
      title: 'Buster Brown',
      updated_at: '2017-02-22T15:56:57+0000'
    }
  }, {
    first_name: 'Betsy',
    last_name: 'Brown',
    member_id: 2821838,
    fundraising_page: {
      alias: null,
      campaign_id: 164598,
      commitment: null,
      cover_photo_id: null,
      created_at: '2017-02-22T15:56:57+0000',
      designation_id: null,
      ended_at: null,
      fundraising_team_id: 102761,
      goal: null,
      id: 525085,
      intro_text: 'Help us raise money for CP+WePay',
      logo_id: null,
      logo_url: null,
      member_email_text: null,
      member_id: 2821838,
      organization_id: 31836,
      started_at: null,
      status: 'pending_questions',
      team_role: null,
      thank_you_text: null,
      thankyou_email_text: null,
      title: 'Betsy Brown',
      updated_at: '2017-02-22T15:56:57+0000'
    }
  }],
  fundraising_team: {
    campaign_id: 164598,
    city: null,
    country: null,
    cover_photo_id: null,
    cover_photo_url: null,
    created_at: '2017-02-22T03:48:32+0000',
    description: '<p>Headline</p>',
    goal: '10000.00',
    id: 102761,
    logo_id: null,
    logo_url: null,
    name: "Tom's Team 4",
    organization_id: 31836,
    parent_id: null,
    postal_code: null,
    root_id: null,
    state: null,
    status: 'active',
    team_lead_id: 2428703,
    thank_you_text: null,
    updated_at: '2017-02-22T03:48:32+0000',
    overview: {
      donors_count: 18,
      fees_amount: '0',
      gross_amount: '0',
      net_amount: '0',
      transactions_count: '0',
      registrations_amount: '110.00',
      donations_amount: '0',
      fundraisers_count: 2
    }
  }
});
})();