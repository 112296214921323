(function(){
'use strict';

angular.module('classy.frs').component('runWalkField', {
  template: '\n      <form-column>\n\n        <form-label>Will attendee run or walk?</form-label>\n\n        <radio-control\n          ng-model="$ctrl.model"\n          value="\'run\'"\n          ng-model-options="{ getterSetter: $ctrl.getterSetter }">\n          Run\n        </radio-control>\n\n        <radio-control\n          ng-model="$ctrl.model"\n          value="\'walk\'"\n          ng-model-options="{ getterSetter: $ctrl.getterSetter }">\n          Walk\n        </radio-control>\n\n      </form-column>\n    ',
  bindings: {
    model: '=',
    getterSetter: '<?'
  }
});
})();