(function(){
'use strict';

angular.module('classy.frs').component('checkoutSection', {
  transclude: {
    headline: '?cptHeadline',
    subheadline: '?cptSubheadline',
    body: 'cptBody',
    bypass: '?cptBypass'
  },
  bindings: {
    hideBanner: '<?'
  },
  template: '\n      <div class="checkout-section" aria-labelledby="checkoutSection_heading">\n\n        <header ng-if="!$ctrl.hideBanner" class="checkout-section__banner">\n          <div id="checkoutSection_heading">\n\n            <h1 class="checkout-section__headline"\n            data-ng-transclude="headline"></h1>\n\n            <p class="checkout-section__subheadline"\n            data-ng-transclude="subheadline"></p>\n\n          </div>\n        </header>\n\n        <div class="checkout-section__body"  ng-class="{\'no-banner\': $ctrl.hideBanner}">\n\n          <div data-ng-transclude="body"></div>\n\n          <div class="checkout-section__bypass"\n          data-ng-transclude="bypass"></div>\n\n        </div>\n\n      </div>\n    '
});
})();