(function(){
'use strict';

angular.module('classy.frs').component('registrationDonation', {
  template: '\n      <checkout-section>\n\n        <cpt-headline>Add a Donation</cpt-headline>\n\n        <cpt-body>\n          <ng-include ng-if="$ctrl.preventRecurringFrequency" src="$ctrl.tpl(\'donation\')"></ng-include>\n\n          <ng-include ng-if="!$ctrl.preventRecurringFrequency" src="$ctrl.tpl(\'rf-donation\')"></ng-include>\n        </cpt-body>\n\n        <cpt-bypass>\n          <a ng-click="$ctrl.skip()" tabindex="0" role="link">\n            Skip donation\n          </a>\n        </cpt-bypass>\n\n        </checkout-section>\n        ',
  controller: ["$scope", "registrationNavigator", "registrationRoutes", "scBlocksService", "scCartService", "scThemesService", "scValidityService", "scCampaignsService", "scOrganizationsService", "scFlowModalService", "scAnalytics", "scCurrencyService", "accessibilityService", function controller($scope, registrationNavigator, registrationRoutes, scBlocksService, scCartService, scThemesService, scValidityService, scCampaignsService, scOrganizationsService, scFlowModalService, scAnalytics, scCurrencyService, accessibilityService) {
    var _this = this;

    var DONATION_ROUTE = registrationRoutes.DONATION_ROUTE,
        TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE;

    /**--------------------------------------------------------
     * RF Changes to get frequency and donation Values
     * --------------------------------------------------------
     */

    this.DonationBlock = scBlocksService.getBlockByType('donation');

    this.frequencyLevels = this.DonationBlock.current['recurring-level'];

    this.selectAmount = function (amount, keyboardEvent) {
      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;

      /**
       * If cart is having donation_amount by selecting custom amount and is revisiting "Add a Donation" page
       * in this scenario custom input field should remember the donation_amount which was provided. */
      var customInputAmt = amount !== null && !_this.donationLevels.some(function (item) {
        return item.amount === amount;
      });

      _this.customAmount = amount === 'Custom' || customInputAmt;
      if (!_this.customAmount) {
        _this.amount = amount;
        _this.showCustomInputField = false;
      } else {
        _this.amount = customInputAmt ? amount : null;
        _this.showCustomInputField = true;
      }

      _this.cart.current.donation_amount = _this.amount;
    };

    this.selectOnInit = function (levelAmount) {
      if (levelAmount === _this.cart.current.donation_amount) {
        _this.selectAmount(_this.cart.current.donation_amount);
      }

      // If levelAmount === 'Custom' and cart.current.donation_amount is not in donationLevels
      // it will select the custom amount
      if (levelAmount === 'Custom' && !_this.donationLevels.some(function (item) {
        return item.amount === _this.cart.current.donation_amount;
      })) {
        _this.selectAmount('Custom');
      }
    };

    this.getAriaLabel = function (frequency) {
      var ariaLabel = void 0;
      switch (frequency) {
        case 'one-time':
        case 'monthly':
        case 'yearly':
          ariaLabel = 'Make a ' + frequency + ' gift';
          break;
        case 'quarterly':
          ariaLabel = 'Make a gift every three months';
          break;
        case 'semi-annually':
          ariaLabel = 'Make a gift every six months';
          break;
        default:
          break;
      }
      return ariaLabel;
    };

    // Highlight if other than custom amount is set as default
    this.donationLevelStyle = function (displayAmt) {
      var styles = {
        color: !_this.showCustomInputField && _this.amount === displayAmt ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        'background-color': !_this.showCustomInputField && _this.amount === displayAmt ? _this.primaryColor : '#E7EBEE'
      };
      return styles;
    };

    // Highlight if custom amount is set as default
    this.otherBtnStyle = function () {
      var styles = {
        color: _this.showCustomInputField ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        'background-color': _this.showCustomInputField ? _this.primaryColor : '#E7EBEE'
      };
      return styles;
    };

    // logic for frequency dropdown
    this.noOfFrequencyLevel = function () {
      return _this.frequencyLevels && _this.frequencyLevels.filter(function (level) {
        return level.display_on_page;
      }).length;
    };

    // logic for donation amount
    this.noOfDonationAmount = function () {
      return _this.donationLevels && _this.donationLevels.filter(function (item) {
        return item.display_on_page;
      }).length;
    };

    // logic for custom button
    this.showCustomAmtBtn = function () {
      return _this.donationLevels && _this.donationLevels.some(function (amt) {
        return amt.display_on_page && amt.amount === 'Custom';
      });
    };

    // hide 'daily', 'weekly', 'bi-weekly' frequency for deque
    this.frequencyForDeque = function (frequency) {
      return _this.preventOldrfForDeque && ['daily', 'weekly', 'bi-weekly'].includes(frequency);
    };

    /* -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      var _this2 = this;

      this.donationPresets = this.getDonationLevels();
      this.cart = scCartService.active;
      this.formName = 'donationForm';
      this.validityModel = scValidityService.get('registration').clear();
      this.deregisterChange = this.onChange();
      this.preventRecurringFrequency = scOrganizationsService.active.preventRecurringFrequencies();
      this.preventOldrfForDeque = scOrganizationsService.active.preventOldrfForDeque();
      this.raw_currency_code = scCampaignsService.active.current.raw_currency_code || scCurrencyService.getDefaultCurrency();

      this.cart.current.donation_frequency = this.cart.current.donation_frequency || this.DonationBlock.current['recurring-default'] || 'one-time';

      this.campaign = scCampaignsService.active;
      var campaign = this.campaign.current;

      this.fotFlag = campaign.dcf_enabled ? 'donation_donor_covered_fee' : 'donation_fee_on_top';

      /**
       * If the cart has a donation_amount, we assume the user has already made their choice about
       * donation_fee_on_top and is revisiting the "Add a Donation" page. In this scenario, we use
       * this.current.cart.donation_fee_on_top to determine the checkbox state. Otherwise, if the
       * cart has a donation_amount of 0, we use the campaign's default value for fee on top.
       */
      this.cart.current[this.fotFlag] = this.cart.current.donation_amount ? this.cart.current[this.fotFlag] : campaign.classy_mode_checked_by_default;

      this.donationCrediteeId = null;

      // Show dark outline around scCurrencyPicker input on focus, hide on blur
      this.show = {
        inputFocus: false
      };

      // Initialize this.donationLevels based on recurring-default
      var donationLevelType = this.DonationBlock.current['recurring-default'] === 'one-time' ? 'donation-levels' : 'recurring-donation-levels';
      this.donationLevels = this.DonationBlock.current[donationLevelType];

      // Check if the only item with display_on_page = true is 'Custom'
      // If so, show the custom input field by default
      function showCustomAmount(donationLevels) {
        var displayOnPage = donationLevels.filter(function (item) {
          return item.display_on_page === true;
        });
        return displayOnPage.length === 1 && displayOnPage[0].amount === 'Custom';
      }

      this.showCustomInputField = showCustomAmount(this.donationLevels);

      // Watch for changes in donation frequency
      $scope.$watch(function () {
        return _this2.cart.current.donation_frequency;
      }, function (newVal) {
        // important that we fire even if newVal == oldVal since user can backtrack
        donationLevelType = newVal === 'one-time' ? 'donation-levels' : 'recurring-donation-levels';
        _this2.donationLevels = _this2.DonationBlock.current[donationLevelType];

        _this2.showCustomInputField = showCustomAmount(_this2.donationLevels);
      });

      accessibilityService.initiatePageFocus('.checkout-layout__main');
    };

    this.$onDestroy = function () {
      this.deregisterChange();
    };

    /* -------------------------------------------------------------------- *
     * Form
     * -------------------------------------------------------------------- */

    this.submit = function () {
      var reporter = this.validityModel.reporters[this.formName];
      return reporter.valid && registrationNavigator.next();
    };

    /* -------------------------------------------------------------------- *
     * Nav interceptors
     * -------------------------------------------------------------------- */

    this.onChange = function () {
      var _this3 = this;

      // eslint-disable-next-line no-unused-vars
      return registrationNavigator.on('change', function (fromRoute, fromParams, toRoute, toParams) {
        if (fromRoute === DONATION_ROUTE && toRoute === TICKETS_ROUTE && scCartService.active.isReserved && !_this3.campaign.HAS_FUNDRAISING) {
          registrationNavigator.confirmExit(function (reroute) {
            if (reroute) {
              scCartService.create().then(function () {
                scFlowModalService.close();
                registrationNavigator.go(TICKETS_ROUTE);
              });
            }
          });
          return false;
        } else if (fromRoute === DONATION_ROUTE && toRoute !== TICKETS_ROUTE) {
          if (!_this3.cart.current.donation_amount) {
            _this3.cart.current.donation_answers = null;
            _this3.cart.current.donation_frequency = null;
            _this3.cart.current.donation_fee_on_top = null;
            _this3.cart.current.donation_applied_fot_percent = null;
          } else if (_this3.cart.current.donation_fee_on_top === null) {
            _this3.cart.current.donation_fee_on_top = false;
          }

          // Set update cart registrant with donation credit if user selects a
          // page to credit to. Otherwise, we default to crediting the campaign with
          // the donation
          // Note: leave this version separate from the one which passes in no callback
          // in order to allow for simplification of scCartModel methods
          if (_this3.donationCrediteeId && _this3.cart.current.donation_amount) {
            var oldCreditee = _this3.cart.getCurrentRegistrantDonationCreditee();

            // Save the cart, then update the registrant, then refresh the cart
            // Ensures that data is set in the correct order
            return _this3.cart.saveData({}, function () {
              return _this3.cart.updateRegistrant(_this3.donationCrediteeId, {
                donation_share: 1
              }).then(function () {
                // if an old creditee exists, we need to wipe the donation from that person
                if (oldCreditee) {
                  oldCreditee.donation_share = null;
                  return _this3.cart.updateRegistrant(oldCreditee.id, {
                    donation_share: null
                  });
                }
                return Promise.resolve();
              });
            });
          }

          return _this3.cart.saveData({});
        }

        return undefined;
      });
    };

    this.updateDonationFrequency = function () {
      scAnalytics.eventBeacon({
        category: 'frs ' + scCampaignsService.active.current.type,
        action: 'click',
        label: 'Donation Frequency \u2013 ' + this.cart.current.donation_frequency + ' (non-text link)'
      });
    };

    /* -------------------------------------------------------------------- *
     * Templates
     * -------------------------------------------------------------------- */

    this.tpl = function (slug) {
      return 'frs/registration/components/donation/inc/' + slug;
    };

    // Only one-time and monthly in Phase 1
    this.frequencyOptions = [{
      label: 'One-time',
      value: 'one-time'
    }, {
      //   label: 'Daily',
      //   value: 'daily'
      // }, {
      //   label: 'Bi-weekly',
      //   value: 'bi-weekly'
      // }, {
      label: 'Monthly',
      value: 'monthly'
      // }, {
      //   label: 'Bi-monthly',
      //   value: 'bi-monthly'
      // }, {
      //   label: 'Quarterly',
      //   value: 'quarterly'
      // }, {
      //   label: 'Yearly',
      //   value: 'yearly'
    }];

    var addDonationEventSent = false;

    this.$doCheck = function () {
      if (parseFloat(_this.cart.current.donation_amount) && !addDonationEventSent) {
        scAnalytics.eventBeacon({
          category: 'frs ' + scCampaignsService.active.current.type,
          action: 'add',
          label: 'Add to Cart',
          product_name: 'Additional Donation',
          price: _this.cart.current.donation_amount,
          currency: _this.raw_currency_code
        });
        addDonationEventSent = true;
      } else if (!parseFloat(_this.cart.current.donation_amount) && addDonationEventSent) {
        addDonationEventSent = false;
      }
    };

    this.primaryColor = scThemesService.active.current.styles.primaryColor;

    /* -------------------------------------------------------------------- *
     * Setup
     * -------------------------------------------------------------------- */

    this.getDonationLevels = function () {
      return scBlocksService.getBlockByType('donation').current['donation-levels'].filter(function (item) {
        return item.amount !== 'Custom';
      }).map(function (level) {
        return level.amount;
      });
    };

    this.skip = function () {
      if (this.cart.current.donation_amount) {
        this.cart.current.donation_amount = null;
        this.cart.current.donation_frequency = null;
        this.cart.current.donation_fee_on_top = null;
        this.cart.current.donation_applied_fot_percent = null;
        this.cart.saveData().then(function () {
          registrationNavigator.next();
        });
      } else {
        registrationNavigator.next();
      }
    };
  }]
});
})();