(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy.frs').component('registrationTickets', {
  template: '\n    <checkout-section>\n      <cpt-headline _spec="headline">Select {{::$ctrl.ticketTerm}}\n      </cpt-headline>\n      <cpt-subheadline ng-if="$ctrl.isRwf">A fundraising page will be created for each registrant. Be on the lookout for a follow-up email from us containing details on how to manage your fundraising page.</cpt-subheadline>\n\n      <cpt-body>\n\n        <div class="ticket-type__list" ng-if="$ctrl.enableRegistrations">\n          <registration-ticket-type data-ng-repeat="type in $ctrl.ticketTypes"\n            name="type.name"\n            id="type.name_{{$index}}"\n            ticket-id="type.id"\n            description="type.description"\n            price="type.price | currency:\'$\'"\n            is-disabled="$ctrl.typeDisabled(type)"\n            current-quantity="$ctrl.cart.quantityOf(type)"\n            max-quantity="$ctrl.maxQuantity(type)"\n            attendees-per="type.entries_per_ticket"\n            commitment="type.commitment"\n            on-add="$ctrl.cart.stageItem(type)"\n            on-remove="$ctrl.cart.unstageItem(type)"\n            sold-out="$ctrl.soldOutTickets[type.id]">\n          </registration-ticket-type>\n        </div>\n\n        <div class="ticket-type__empty"\n          ng-if="!$ctrl.enableRegistrations"\n          _spec="noTickets">\n          <h2 class="empty-ticket-headline">\n            No {{::$ctrl.ticketTerm}} are currently available for this event.\n          </h2>\n          <p class="empty-ticket-description">\n            Contact {{::$ctrl.campaignContactEmail}} for more information.\n          </p>\n        </div>\n\n      </cpt-body>\n    </checkout-section>\n    ',
  controller: ["$state", "registrationNavigator", "registrationRoutes", "scCampaignsService", "scThemesService", "scCartService", "scAnalytics", "scCurrencyService", "accessibilityService", function controller($state, registrationNavigator, registrationRoutes, scCampaignsService, scThemesService, scCartService, scAnalytics, scCurrencyService, accessibilityService) {
    var _this2 = this;

    var TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
        ATTENDEE_ROUTE = registrationRoutes.ATTENDEE_ROUTE,
        NEW_ATTENDEE_ROUTE = registrationRoutes.NEW_ATTENDEE_ROUTE,
        DONATION_ROUTE = registrationRoutes.DONATION_ROUTE,
        PAYMENT_ROUTE = registrationRoutes.PAYMENT_ROUTE;


    this.campaignContactEmail = scCampaignsService.active.current.contact_email;
    this.ticketTerm = scThemesService.active.getTicketTerm('plural');
    this.donationEnabled = _.get(scThemesService.active.getEventStyles(), 'ticketedEvent.additionalDonation', true);
    this.isRwf = scCampaignsService.active.isType('reg_w_fund');

    /* -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */
    this.$onInit = function () {
      this.cart = scCartService.active;
      this.deregisterBeforeChange = this.onBeforeChange();
      this.attendeeIndex = $state.params.attendeeIndex;
      accessibilityService.initiatePageFocus('.checkout-layout__main');

      // Begin Checkout Event
      var currency = scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current);
      scAnalytics.eventBeacon({
        label: 'Begin Checkout',
        action: 'checkout',
        currency: currency,
        items: [{
          item_id: scCampaignsService.active.current.id,
          item_name: scCampaignsService.active.current.name,
          item_category: scCampaignsService.active.current.type,
          currency: currency
        }]
      });
    };

    this.$onDestroy = function () {
      this.deregisterBeforeChange();
    };

    /* -------------------------------------------------------------------- *
     * Before Change
     * -------------------------------------------------------------------- */

    this.onBeforeChange = function () {
      var _this = this;

      return registrationNavigator.on('beforeChange',
      // eslint-disable-next-line no-unused-vars
      function (fromRoute, fromParams, toRoute, toParams) {
        if (fromRoute === TICKETS_ROUTE && (toRoute === ATTENDEE_ROUTE || toRoute == NEW_ATTENDEE_ROUTE)) {
          return _this.cart.reserve().then(function (data) {
            // Let stagedItems point to the same object as currentItems
            // for proper updating of the registrationCartList
            _this.cart.stagedItems = _.cloneDeep(_.get(data, 'current.items')) || _this.cart.stagedItems;
            // FB Pixel tracking for registrations in cart
            var stagedItemsData = _.merge(data.saved, {
              amount: _this.cart.itemsSubtotal,
              raw_currency_code: scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current)
            });

            scAnalytics.track('registration/create/begin', stagedItemsData);

            // For items with multiple attendees, create registrants array
            _.forEach(_this.cart.stagedItems, function (item) {
              var entries = _.get(item, 'entries', false);
              if (entries) {
                item.registrants = [];
                _.times(entries, function (i) {
                  item.registrants.push(_this.cart.getRegistrant(item.id, i));
                });
              }
            });

            // for the ticketed event type, we need to do some special skipping (sometimes)
            var HAS_FUNDRAISING = scCampaignsService.active.HAS_FUNDRAISING;
            var firstItemIdx = _this.cart.firstItemWithQuestionsIdx(_this.cart.stagedItems);

            // get the index of the first staged item that has a question, we will use this
            // as a state transition param; otherwise we will just use 0
            var firstIdx = !HAS_FUNDRAISING ? firstItemIdx : 0;

            // the only situation where this index is -1 is when the event is a ticketed type and
            // there are no registrations that have any custom Qs
            if (firstIdx === -1) {
              if (_this.donationEnabled) {
                return {
                  route: DONATION_ROUTE,
                  params: {
                    cartId: _this.cart.current.id
                  }
                };
              }
              return {
                route: PAYMENT_ROUTE,
                params: {
                  cartId: _this.cart.current.id
                }
              };
            }

            return {
              route: NEW_ATTENDEE_ROUTE,
              params: {
                attendeeIndex: firstIdx,
                cartItemId: _this.cart.current.items[firstIdx].id
              }
            };
          }).catch(_this.handleReserveError);
        }

        return undefined;
      });
    };

    /* -------------------------------------------------------------------- *
     * Ticket types
     * -------------------------------------------------------------------- */

    this.filterTicketType = function (type) {
      var now = moment();

      var afterEnd = now > moment(type.ended_at);
      var beforeStart = now <= moment(type.started_at);

      return !beforeStart && !afterEnd;
    };

    this.ticketTypes = _(scCampaignsService.active.current.ticketTypes).filter(this.filterTicketType).filter(function (type) {
      // Unlimited
      if (type.quantity_available === null) return true;

      // Count remaining
      var quantityUnavailable = type.quantity_reserved + type.quantity_sold;
      var quantityRemaining = type.quantity_available - quantityUnavailable;
      return quantityRemaining > 0;
    }).sortBy(['weight', 'started_at']).value();

    this.enableRegistrations = function () {
      // Non active campaigns are a no go
      if (scCampaignsService.active.isInactive()) return false;

      // Must have at least 1 active ticket
      if (!_this2.ticketTypes.length) return false;

      // Must have fundraising 'open' if the type is reg w fundraising
      if (scCampaignsService.active.current.type === 'reg_w_fund' && !scCampaignsService.active.current.allow_fundraising_pages) {
        return false;
      }

      return true;
    }();

    this.typeDisabled = function (type) {
      var now = moment();
      var started = moment(type.started_at);
      var ended = moment(type.ended_at);
      return started > now || ended < now;
    };

    this.maxQuantity = function (type) {
      // Unlimited
      if (type.quantity_available === null) return type.max_per_transaction;
      // Count remaining
      var quantityUnavailable = type.quantity_reserved + type.quantity_sold;
      var quantityRemaining = type.quantity_available - quantityUnavailable;
      return Math.min(type.max_per_transaction, quantityRemaining);
    };

    /* -------------------------------------------------------------------- *
     * soldOutTickets()
     *
     * Keeps track of which tickets have gone out of stock since the user
     * got to the checkout page.
     * -------------------------------------------------------------------- */
    this.soldOutTickets = {};

    /* -------------------------------------------------------------------- *
     * handleReserveError()
     *
     * Called if the api responsed with an error upon cart reservation. This
     * will parse the errors and only add them to the ticketTypeErrors if they
     * are user friendly.
     * -------------------------------------------------------------------- */
    this.handleReserveError = function (_ref) {
      var _ref$data = _ref.data,
          data = _ref$data === undefined ? {} : _ref$data;

      var outOfStockMessage = 'This product is no longer in stock';

      Object.keys(data.error || {}).forEach(function (key) {
        var productId = _.get(_this2.cart, ['stagedItems'].concat(_toConsumableArray(key.split('.'))));
        _this2.cart.unstageItemById(productId);
        if (data.error[key].includes(outOfStockMessage)) {
          _this2.soldOutTickets[productId] = true;
        }
      });

      return {
        route: TICKETS_ROUTE
      };
    };
  }]
});
})();