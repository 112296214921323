(function(){
'use strict';

angular.module('classy.frs').component('registrantTeam', {
  template: '\n      <article class="registrant--team">\n\n        <div class="registration__avatar">\n          <img class="registrant__avatar-image"\n          ng-src="{{:: $ctrl.logo }}" />\n        </div>\n\n        <div class="registrant__fundraiser">\n\n          <h1 class="registrant__fundraiser-name">{{:: $ctrl.team.name }}</h1>\n\n          <p class="registrant__fundraiser-details">\n            {{:: $ctrl.team.overview.fundraisers_count }} Member{{$ctrl.team.overview.fundraisers_count > 1 ? \'s\' : \'\'}}\n            <span ng-if="$ctrl.team.overview.gross_amount">&bull;\n            Raised {{:: $ctrl.team.overview.gross_amount | currency:\'$\':2 }}</span>\n          </p>\n\n        </div>\n\n        <div class="registrant__action">\n\n          <a class="registrant__action-button"\n          ui-sref="frs.team.index({ fundraisingTeamId: $ctrl.team.id })"\n          ng-style="{ \'background-color\': $ctrl.primaryColor }">\n            View\n          </a>\n\n        </div>\n      </article>\n    ',
  bindings: {
    team: '<',
    primaryColor: '<',
    fallbackLogoUrl: '<'
  },
  controller: ["scAnalytics", "scSourceCodes", function controller(scAnalytics, scSourceCodes) {
    var _this = this;

    this.$onInit = function () {
      _this.logo = _this.team.logo_url || _this.fallbackLogoUrl;

      // Tell scAnalytics we've created a new fundraising team.
      scAnalytics.eventBeacon({
        category: 'frs reg_w_fund',
        action: 'creation',
        label: 'New Team',
        meta: scSourceCodes.getLatest(_this.team.campaign_id),
        team: _this.team
      });
    };
  }]
});
})();