(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy.frs').component('registrationCaptain', {
  template: '\n      <checkout-section>\n        <cpt-headline class="select-captain__headline">Select Team Captain</cpt-headline>\n        <cpt-subheadline class="select-captain__subheadline">\n          This person will motivate and run the team\n        </cpt-subheadline>\n        <cpt-body>\n          <ng-include src="$ctrl.tpl(\'captain\')"></ng-include>\n        </cpt-body>\n      </checkout-section>\n    ',
  controller: ["scCartService", "scFundraisingPagesService", "scThemesService", function controller(scCartService, scFundraisingPagesService, scThemesService) {
    this.tpl = function (slug) {
      return 'frs/registration/components/captain/inc/' + slug;
    };

    this.$onInit = function () {
      this.cart = scCartService.active;

      this.buildRegistrants();

      // styles and aesthetics and such
      this.logo = scFundraisingPagesService.getPagePhoto();
      this.primaryColor = scThemesService.active.current.styles.primaryColor;

      this.applyButtonStyles();
    };

    this.$doCheck = function () {
      // the amount of attendees there should be is no longer equal to the amount there are
      if (_.sum(this.cart.current.items, function (item) {
        return item.entries;
      }) != this.registrants.length) {
        this.buildRegistrants();
        this.applyButtonStyles();
      }

      // if we removed the captain registrant we BETTER not keep their ID in play
      if (!_.find(this.registrants, { id: this.cart.current.team_lead_id })) {
        this.cart.current.team_lead_id = null;
      }

      // if we remove everyone except one registrant, we should just go ahead and
      // select that registrant as captain while we're on this page awkwardly
      if (this.registrants.length === 1) {
        this.cart.current.team_lead_id = this.registrants[0].id;
      }
    };

    this.buildRegistrants = function () {
      var _Array$prototype;

      // giant list of all registrations and such
      this.registrants = (_Array$prototype = Array.prototype).concat.apply(_Array$prototype, [].concat(_toConsumableArray(this.cart.current.items.map(function (items) {
        return items.registrants;
      }))));
    };

    this.applyButtonStyles = function () {
      var _this = this;

      // throwing some default styles on their buttons
      _.forEach(this.registrants, function (registrant) {
        registrant.style = { 'border-color': _this.primaryColor, color: _this.primaryColor };
      });
    };

    this.mutiny = function (registrant) {
      // look at me, im the captain now
      this.cart.current.team_lead_id = registrant.id;
      // do this to reset styles so they aren't all wonkadoodle
      this.hoverStyle(registrant, false);
    };

    this.isCaptain = function (id) {
      // am i the captain?
      return id === this.cart.current.team_lead_id;
    };

    // had to do this because of outline buttons being a real pain to overwrite with
    // a campaign's primary color
    this.hoverStyle = function (registrant, hovering) {
      if (!hovering) {
        registrant.style = { 'border-color': this.primaryColor, color: this.primaryColor };
      } else {
        registrant.style = {
          'border-color': this.primaryColor,
          color: 'white',
          'background-color': this.primaryColor
        };
      }
    };
  }]
});
})();