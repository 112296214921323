(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy.frs').component('shirtSizeField', {
  template: '\n      <form-column>\n\n        <div class="shirt-field">\n\n          <div class="shirt-field__content">\n          \n            <div class="shirt-field__description">\n              <p>This is the official event shirt. It\'s a super-soft shirt that you\n              can enjoy wearing during and after the event.</p><br />\n              <ul>\n                <li>100% Cotton, Pre-Shrunk</li>\n                <li>Included in price</li>\n              </ul>\n            </div>\n\n            <form-label>Shirt size</form-label>\n            <select class="form__select"\n            ng-model="$ctrl.model"\n            ng-model-options="{ getterSetter: true }"\n            data-ng-options="option for option in $ctrl.options">\n              <option value="">Please Select</option>\n            </select>\n\n          </div>\n\n          <figure class="shirt-field__figure">\n            <img class="shirt-field__image" src="https://placehold.it/166x210" />\n          </figure>\n\n        </div>\n\n      </form-column>\n    ',
  bindings: {
    model: '='
  },
  controller: function () {
    function ShirtSizeFieldCtrl() {
      _classCallCheck(this, ShirtSizeFieldCtrl);
    }

    _createClass(ShirtSizeFieldCtrl, [{
      key: '$onInit',
      value: function $onInit() {
        this.options = ['Adult - X Small', 'Adult - Small', 'Adult - Medium', 'Adult - Large', 'Adult - X Large', 'Child - X Small', 'Child - Small', 'Child - Medium', 'Child - Large', 'Child - X Large'];
      }
    }]);

    return ShirtSizeFieldCtrl;
  }()
});
})();