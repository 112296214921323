(function(){
'use strict';

angular.module('classy.frs').component('registrationCartAlert', {
  template: '\n      <div class="cart-alert"\n      ng-class="{\'mobile\': $ctrl.mobile}">\n\n        <p class="cart-alert__title">\n          {{ $ctrl.title }}\n        </p>\n\n        <p class="cart-alert__description">\n          {{ $ctrl.description }}\n        </p>\n\n        <button class="cart-alert__dismiss"\n        ng-click="$ctrl.onDismiss()"\n        ng-style="{ \'background-color\': $ctrl.primaryColor }">\n          {{ $ctrl.dismissText }}\n        </button>\n\n        <a class="cart-alert__exit"\n        ng-if="$ctrl.exitText"\n        ng-click="$ctrl.onExit()">\n          {{ $ctrl.exitText }}\n        </a>\n\n      </div>\n    ',
  bindings: {
    title: '<',
    description: '<',
    dismissText: '<',
    onDismiss: '&',
    exitText: '<?',
    onExit: '&?',
    primaryColor: '<',
    mobile: '<?'
  }
});
})();