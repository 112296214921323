(function(){
'use strict';

frsLandingRoutesConfig.$inject = ["$stateProvider", "$urlRouterProvider", "scConstants"];
angular.module('classy.frs').config(frsLandingRoutesConfig);

function frsLandingRoutesConfig($stateProvider, $urlRouterProvider, scConstants) {
  // Updates routes from /campaign/c12345 to /event/e12345 and vice versa
  // depending on campaign type
  $urlRouterProvider.rule(function ($injector, $location) {
    var EVENT_RE = /event\/(.*?)\/e(\d+)(\/|$)(\/?.*?)$/;
    var CAMPAIGN_RE = /campaign\/(.*?)\/c(\d+)(\/?.*?)$/;
    var $window = $injector.get('$window');
    var campaignType = _.get($window, 'SC.campaign.type');
    var fullPath = $location.path();
    var eventMatches = fullPath.match(EVENT_RE);
    var campaignMatches = fullPath.match(CAMPAIGN_RE);

    if (eventMatches && campaignType !== 'reg_w_fund' && campaignType !== 'ticketed') {
      return fullPath.replace(eventMatches[0], 'campaign/' + eventMatches[1] + '/c' + eventMatches[2] + eventMatches[3]);
    } else if (campaignMatches && (campaignType === 'reg_w_fund' || campaignType == 'ticketed')) {
      return fullPath.replace(campaignMatches[0], 'event/' + campaignMatches[1] + '/e' + campaignMatches[2] + campaignMatches[3]);
    }

    return undefined;
  });

  $stateProvider.state('frs.landing', {
    url: '/{eventType:campaign|event}?promo',
    abstract: true,
    templateUrl: 'frs/shared/views',
    controller: 'frs-landingCtrl',
    params: {
      eventType: 'campaign'
    }
  }).state('frs.landing.name', {
    url: '/{eventName:[^/]*}',
    abstract: true
  }).state('frs.landing.name.campaign', {
    url: '/{typeSlug:[ce]}{campaignId}?preview&register&campaign-templating-view&' + scConstants.urlParams.utmCodes,
    params: {
      typeSlug: 'c'
    },
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/shared/blocks'
      }
    },
    metaTags: {
      prerender: {
        header: function header() {
          return 'Location:' + window.location.href;
        }
      },
      title: _.get(SC, 'campaign.name', '') + ' - Campaign',
      description: ['getMetaTags', function (obj) {
        return obj.description;
      }],

      properties: {
        'og:image': ['getMetaTags', 'scBrandingService', function (obj, scBrandingService) {
          var image = scBrandingService.getShareImage('campaign');
          return obj.image_url ? obj.image_url : image;
        }],

        'twitter:image': ['getMetaTags', 'scBrandingService', function (obj, scBrandingService) {
          var image = scBrandingService.getShareImage('campaign');
          return obj.image_url ? obj.image_url : image;
        }],

        'og:video': ['getMetaTags', function (obj) {
          return obj.video_url;
        }],

        'og:video:secure_url': ['getMetaTags', function (obj) {
          return obj.video_url;
        }],

        'og:video:type': ['getMetaTags', function (obj) {
          return obj.video_type;
        }],

        'og:video:width': ['getMetaTags', function (obj) {
          return obj.video_width;
        }],

        'og:video:height': ['getMetaTags', function (obj) {
          return obj.video_height;
        }],

        'og:description': ['getMetaTags', function (obj) {
          return obj.description;
        }],

        'twitter:description': ['getMetaTags', function (obj) {
          return obj.description;
        }],

        'og:title': _.get(SC, 'campaign.name', ''),

        'twitter:title': _.get(SC, 'campaign.name', '')
      }
    }
  }).state('frs.landing.name.campaign.search', {
    url: '/search?q&type',
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/search/template',
        controller: 'frs-searchCtrl'
      }
    },
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        return 'Search ' + _.get(scCampaignsService, 'active.current.name', '');
      }]
    }
  }).state('frs.landing.name.campaign.unsubscribe', {
    url: '/notification-preferences?recipient&email',
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/unsubscribe/template',
        controller: 'frs-unsubscribeCtrl'
      }
    },
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        return 'Notification Preferences ' + _.get(scCampaignsService, 'active.current.name', '');
      }]
    }
  }).state('frs.landing.name.campaign.create-fundraising-page', {
    url: '/fundraiser/create?amount',
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/shared/blocks',
        controller: 'frs-createFundraisingPageCtrl'
      }
    },
    params: {
      fundraisingTeamId: undefined
    },
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        var title = 'Create a Fundraising Page';
        var name = _.get(scCampaignsService, 'active.current.name', false);
        if (name) {
          title += ' for ' + name;
        }

        return title;
      }]
    },
    resolve: {
      resolvedUserFundraisers: ['$http', 'scMembersService', 'scCampaignsService', function ($http, scMembersService, scCampaignsService) {
        if (_.get(scMembersService, 'active.current.id')) {
          return $http({
            method: 'GET',
            url: '/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-pages',
            params: {
              filter: 'member_id=' + scMembersService.active.current.id,
              status: 'active',
              per_page: 5,
              aggregates: true
            }
          });
        }
        return null;
      }]
    }
  }).state('frs.landing.name.campaign.start-fundraising', {
    url: '/start-fundraising',
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        var title = 'Start Fundraising';
        var name = _.get(scCampaignsService, 'active.current.name', false);
        if (name) {
          title += ' for ' + name;
        }

        return title;
      }]
    },
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/shared/blocks',
        controller: 'startFundraisingCtrl'
      }
    }
  }).state('frs.landing.name.campaign.start-fundraising.search', {
    url: '/search?q&type',
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/search/template',
        controller: 'frs-searchCtrl'
      }
    },
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        return 'Search ' + _.get(scCampaignsService, 'active.current.name', '');
      }]
    }
  }).state('frs.landing.name.campaign.create-fundraising-team', {
    url: '/team/create',
    metaTags: {
      title: ['scCampaignsService', function (scCampaignsService) {
        var title = 'Create a Fundraising Team';
        var name = _.get(scCampaignsService, 'active.current.name', false);
        if (name) {
          title += ' for ' + name;
        }

        return title;
      }]
    },
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/shared/blocks',
        controller: 'frs-createFundraisingTeamCtrl'
      }
    }
  }).state('frs.landing.campaignSimple', {
    url: '/{typeSlug:[ce]}{campaignId}?preview&' + scConstants.urlParams.utmCodes,
    views: {
      'body@frs.landing': {
        templateUrl: 'frs/shared/blocks'
      }
    }
  });
}
})();